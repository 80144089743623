import {
  AutoFixHigh,
  DragIndicatorTwoTone,
  Loop,
  PersonPinOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import {
  IndirizziCodificatiContext,
  NationsContext,
  OrdiniRitiroContext,
} from "../../../context";
import moment from "moment";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useForm } from "../../../hooks";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import { 
  getFlagPath,
  setTimerSearchAnagrafica
} from "../../../utilities";


const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const Mittente = ({
  indicatoriConfig,
  readOnly,
  oraPronta,
  luogoRitiroVisible,
  riferimentoVisible,
  setMainForm,
  mainForm,
}) => {
  const {
    state: { indicatoriMitt },
    getIndicatoriMitt,
  } = useContext(OrdiniRitiroContext);

  const {
    state: { ricercaIndirizzi },
    getSearchIndirizziEsistenti,
    clearRicercaIndirizzi
  } = useContext(IndirizziCodificatiContext);

  const {
    state: { provincesFrom, nations, loading },
    getProvincesListsByNationFrom,
  } = useContext(NationsContext);

  let luogoRitiro = JSON.parse(localStorage.getItem("luogoRitiro"));

  const [checked, setChecked] = React.useState(false);
  const [openListRagSoc, setOpenListRagSoc] = React.useState(false);
  const [openListLocalita, setOpenListLocalita] = React.useState(false);
  const [animationRotate, setanimationRotate] = React.useState(false);
  const [anagraficheSearchTimeOut, setAnagraficheSearchTimeOut] = React.useState(null);

  const [oraProntaLocale, setOraProntaLocale] = React.useState(
    moment().format()
  );
  const [checkedIndicatori, setCheckedIndicatori] = React.useState([]);
  const [localitaAll, setLocalitaAll] = React.useState(
      mainForm.MittCAP +
      "⠀-⠀" +
      mainForm.MittLocalita +
      "⠀-⠀" +
      mainForm.MittProvincia
  );

  const {
    values: mittenteForm,
    changeHandler,
    touched,
    errors,
    setValues,
  } = useForm(
    {
      DataPronta: mainForm.DataPronta,
      OraPronta: mainForm.OraPronta,
      MittNazione: mainForm.MittNazione,
      MittLocalita: mainForm.MittLocalita,
      MittProvincia: mainForm.MittProvincia,
      MittCAP: mainForm.MittCAP,
      MittRagSoc: mainForm.MittRagSoc,
      MittCodiceIndirizzoCodificato: mainForm.MittCodiceIndirizzoCodificato,
      MittIndirizzo: mainForm.MittIndirizzo,
      MittPersona: mainForm.MittPersona,
      MittTelefono: mainForm.MittTelefono,
      MittEmail: mainForm.MittEmail,
      MittRiferimento: mainForm.MittRiferimento,
      NotaRitiro: mainForm.NotaRitiro,
      Orari: mainForm.Orari,
      IndicatoriMitt: mainForm.IndicatoriMitt,
    },
    []
  );

  useEffect(() => {
    getIndicatoriMitt();
  }, []);

  useEffect(() => {
    setMainForm({
      ...mainForm,
      DataPronta: mittenteForm.DataPronta,
      OraPronta: mittenteForm.OraPronta,
      MittNazione: mittenteForm.MittNazione,
      MittLocalita: mittenteForm.MittLocalita,
      MittProvincia: mittenteForm.MittProvincia,
      MittCAP: mittenteForm.MittCAP,
      MittRagSoc: mittenteForm.MittRagSoc,
      MittCodiceIndirizzoCodificato: mittenteForm.MittCodiceIndirizzoCodificato,
      MittIndirizzo: mittenteForm.MittIndirizzo,
      MittPersona: mittenteForm.MittPersona,
      MittTelefono: mittenteForm.MittTelefono,
      MittEmail: mittenteForm.MittEmail,
      MittRiferimento: mittenteForm.MittRiferimento,
      NotaRitiro: mittenteForm.NotaRitiro,
      Orari: mittenteForm.Orari,
      IndicatoriMitt: mittenteForm.IndicatoriMitt,
    });
    setCheckedIndicatori(mittenteForm.IndicatoriMitt)
  }, [mittenteForm]);

  useEffect(() => {
    getProvincesListsByNationFrom(
      mittenteForm.MittNazione,
      setOpenListLocalita
    );
  }, [mittenteForm.MittNazione]);

  const changeValue = (newValue) => {
    setLocalitaAll(newValue);
    if (newValue) {
      setValues({
        ...mittenteForm,
        MittLocalita: newValue.split("⠀-⠀")[1],
        MittProvincia: newValue.split("⠀-⠀")[2] ? newValue.split("⠀-⠀")[2] : "",
        MittCAP: newValue.split("⠀-⠀")[0],
      });
    } else {
      changeHandler("MittLocalita", newValue);
    }
  };

  const anagraficheVisible = () => {
    let config = JSON.parse(localStorage.getItem("configurazione"));
    let anagElement ={};
    config.map((element) => {
      if(element.name === 'anagrafiche'){
        anagElement = element;
      }
    });
    return anagElement.visible;   
  }
  

  const defaultMittente = () => {
    setChecked(!checked);
    if (!checked) {
      setanimationRotate(true);
      setValues({
        ...mittenteForm,
        MittNazione: "IT",
        MittLocalita: "",
        MittProvincia: "",
        MittCAP: "",
        MittRagSoc: "",
        MittIndirizzo: "",
        MittPersona: "",
        MittTelefono: "",
        MittEmail: "",
        MittRiferimento: "",
        NotaRitiro: "",
        Orari: "",
      });
      setLocalitaAll("");
      setTimeout(() => {
        setanimationRotate(false);
      }, 1500);
    } else {
      setanimationRotate(true);
      setTimeout(() => {
        setanimationRotate(false);
      }, 1500);
      setValues({
        ...mittenteForm,
        MittNazione: "IT",
        MittLocalita: luogoRitiro.Localita,
        MittProvincia: luogoRitiro.Provincia,
        MittCAP: luogoRitiro.Cap,
        MittRagSoc: luogoRitiro.RagSoc,
        MittIndirizzo: luogoRitiro.Indirizzo,
        MittPersona: luogoRitiro.Persona,
        MittTelefono: luogoRitiro.Telefono,
        MittEmail: luogoRitiro.Email,
        MittRiferimento: "",
        NotaRitiro: "",
        Orari: "",
      });
      setLocalitaAll(
        luogoRitiro.Cap +
          "⠀-⠀" +
          luogoRitiro.Localita +
          "⠀-⠀" +
          luogoRitiro.Provincia
      );
    }
  };

  //23/05/2023 Gregory
  //aggiunto parametro ragsoc
  //aggiunto controllo su Codice.
  const TrovaMittente = (codice,ragsoc, localita) => {
    //se è presente il campo codice
    let filtro = (codice!==""?(
      ricercaIndirizzi.filter(
        (res) => res.Codice === codice && res.Localita === localita
      )
    )
    :   //se non è presente il campo codice
    (
      ricercaIndirizzi.filter(
        (res) => res.RagSoc === ragsoc && res.Localita === localita
      )
    ))

    valoriTrovaMittente(filtro[0]);
  };

  function valoriTrovaMittente(filtro) {
    setValues({
      ...mittenteForm,
      MittNazione: filtro.Nazione,
      MittLocalita: filtro.Localita,
      MittProvincia: filtro.Provincia,
      MittCAP: filtro.Cap,
      MittIndirizzo: filtro.Indirizzo,
      MittRagSoc: filtro.RagSoc,
      MittPersona: filtro.Persona,
      MittTelefono: filtro.Telefono,
      MittCodiceIndirizzoCodificato: filtro.Codice,
      MittEmail: filtro.Email,
      MittRiferimento: filtro.Riferimento,
      NotaRitiro : filtro.MittNote,   
    });
    /* setCurrentNation(filtro.Nazione) */
    setLocalitaAll(
      filtro.Cap + "⠀-⠀" + filtro.Localita + "⠀-⠀" + filtro.Provincia
    );
  }

  const changeIndicatoriChecked = (isChecked, code) => {
    if (isChecked) {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
        code,
      ]);
      setValues({
        ...mittenteForm,
        IndicatoriMitt: [
          ...checkedIndicatori.filter((res) => res !== code),
          code,
        ],
      });
    } else {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
      ]);
      setValues({
        ...mittenteForm,
        IndicatoriMitt: [...checkedIndicatori.filter((res) => res !== code)],
      });
    }
  };

 //per gestire utenti senza accesso ad anagrafiche
  const getRagSocField = () => {
    if(anagraficheVisible()){
      return(
        <Autocomplete
          id="mittente-ragSoc"
          openOnFocus={openListRagSoc}
          freeSolo
          size="small"
          onOpen={() => setOpenListRagSoc(true)}
          onClose={() => setOpenListRagSoc(false)}
          forcePopupIcon={true}
          options={ricercaIndirizzi.map((option) => {
            return (
              option.Codice ?
              (
                option.Codice +
                "⠀-⠀" +
                option.RagSoc +
                "⠀-⠀" +
                option.Localita
              )
              :
              (
                option.RagSoc +
                "⠀-⠀" +
                option.Localita
              )
            );
          })}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={uuidv4()}>
              {option}
            </Box>
          )}
          value={mittenteForm.MittRagSoc || ""}
          onChange={(e, newValue) => {
            if (newValue !== null) {
              if( newValue.split("⠀-⠀").length === 3){
                TrovaMittente(
                  newValue.split("⠀-⠀")[0],
                  newValue.split("⠀-⠀")[1],
                  newValue.split("⠀-⠀")[2]
                );
              }
              else if(newValue.split("⠀-⠀").length === 2){
                TrovaMittente(
                  "",
                  newValue.split("⠀-⠀")[0],
                  newValue.split("⠀-⠀")[1]
                );
              }
            }
          }}
          inputValue={mittenteForm.MittRagSoc || ""}
          onInputChange={(e, newValue) => {
            //06/06/2024 Greg: gestisco con timer le ricerche in modo da non effettuare troppe ricerce consecutive inutilmente
            if(newValue.length > 0){
              setTimerSearchAnagrafica(
                anagraficheSearchTimeOut,
                setAnagraficheSearchTimeOut,
                getSearchIndirizziEsistenti,
                newValue
              );
            }
            else if (newValue === ""){
              clearRicercaIndirizzi();
              clearTimeout(anagraficheSearchTimeOut);
            }

            changeHandler("MittRagSoc", newValue);       
          }}
          getOptionLabel={(option) => option}
          loading={ricercaIndirizzi.length > 0 && openListRagSoc}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ragione Sociale"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {ricercaIndirizzi.length > 0 && openListRagSoc ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )
    }
    else {
      return(
        <TextField
          disabled={readOnly}
          margin="normal"
          fullWidth
          size="small"
          label="Ragione sociale"
          autoComplete="off"
          value={mittenteForm.MittRagSoc || ""}
          helperText={touched.MittRagSoc && errors.MittRagSoc}
          error={Boolean(touched.MittRagSoc && errors.MittRagSoc)}
          onChange={(e) => changeHandler("MittRagSoc", e.target.value)}
          //disabled={checked}
        />
      )      
    }
  }

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Mittente"}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        subheader={"Dati del mittente"}
        avatar={<PersonPinOutlined />}
        action={
          <Stack direction={"row"} justifyContent="flex-end" spacing={2} p={2}>
            <Button
              color="warning"
              variant="contained"
              size="small"
              disabled={!checked}
              onClick={() => defaultMittente()}
              startIcon={<AutoFixHigh />}
            >
              mittente di default
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={
                animationRotate ? (
                  <Loop
                    color="warning"
                    sx={{
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                ) : (
                  <Loop color="error" />
                )
              }
              disabled={checked}
              onClick={() => defaultMittente()}
            >
              Svuota Campi
            </Button>
          </Stack>
        }
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack spacing={2} direction={"row"}>
              <DatePicker
                label="Data merce pronta"
                value={mittenteForm?.DataPronta || ""}
                onChange={(e, newValue) => {
                  changeHandler("DataPronta", moment(e).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              {oraPronta.visible ? (
                <TimePicker
                  label="Orario merce pronta"
                  disabled={oraPronta.visible ? false : true}
                  value={oraPronta.visible ? oraProntaLocale || "" : null}
                  onChange={(e, newValue) => {
                    setOraProntaLocale(moment(e).format());
                    changeHandler("OraPronta", moment(e).format("hh:mm"));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              ) : (
                <></>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} lg={12} mt={2}>
            {getRagSocField()}
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Indirizzo"
              autoComplete="off"
              value={mittenteForm.MittIndirizzo || ""}
              helperText={touched.MittIndirizzo && errors.MittIndirizzo}
              error={Boolean(touched.MittIndirizzo && errors.MittIndirizzo)}
              onChange={(e) => changeHandler("MittIndirizzo", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="select-from-nations">Nazione</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="select-from-nations"
                id="select-from-nations"
                value={mittenteForm.MittNazione || ""}
                label="Nazione"
                onChange={(e) => {
                  setValues({
                    ...mittenteForm,
                    MittNazione: e.target.value,
                    MittLocalita: "",
                    MittProvincia: "",
                    MittCAP: "",
                    MittIndirizzo: "",
                    MittRagSoc: "",
                    MittPersona: "",
                    MittTelefono: "",
                    MittCodiceIndirizzoCodificato: "",
                    MittEmail: "",
                    MittRiferimento: "",
                  });
                  setLocalitaAll("");
                }}
              >
                <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                {nations?.map((params, key) => (
                  <MenuItem key={key} value={params.nazioneiso}>
                    <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                      <img
                        loading="lazy"
                        width="20"
                        src={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                            : ""
                        }
                        srcSet={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                            : ""
                        }
                        alt=""
                      />
                      {params.descrizione}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {mittenteForm?.MittNazione === "IT" ? (
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                freeSolo
                fullWidth
                forcePopupIcon={openListLocalita}
                value={localitaAll || ""}
                inputValue={localitaAll || ""}
                openOnFocus={openListLocalita}
                onOpen={() => setOpenListLocalita(true)}
                onClose={() => setOpenListLocalita(false)}
                size="small"
                filterOptions={filterOptions}
                id="select-from-provinces"
                groupBy={(option) =>
                  mittenteForm.MittNazione === "IT"
                    ? option.split("⠀-⠀")[1][0]
                    : option[0]
                }
                options={provincesFrom?.map((item) => {
                  return `${item.cap}⠀-⠀${item.localita}${
                    item.provincia ? `⠀-⠀${item.provincia}` : ""
                  }`;
                })}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={uuidv4()}>
                    {option}
                  </Box>
                )}
                loading={provincesFrom.length === 0}
                onInputChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                onChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                disabled={loading || readOnly}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cap - Località - Provincia "
                    sx={{ marginTop: 2 }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            ""
                          )}
                          {params.InputProps.startAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  autoFocus
                  value={mittenteForm.MittLocalita || ""}
                  inputValue={mittenteForm.MittLocalita || ""}
                  size="small"
                  openOnFocus={openListLocalita}
                  onOpen={() => setOpenListLocalita(true)}
                  onClose={() => setOpenListLocalita(false)}
                  id="select-from-provinces"
                  options={provincesFrom?.map((item) => {
                    return item.localita;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  loading={provincesFrom.length === 0}
                  onInputChange={(e, newValue) => {
                    changeHandler("MittLocalita", newValue);
                  }}
                  onChange={(e, newValue) => {
                    changeHandler("MittLocalita", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Località"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  value={mittenteForm.MittCAP || ""}
                  inputValue={mittenteForm.MittCAP || ""}
                  size="small"
                  id="select-from-provinces"
                  options={provincesFrom.map((item) => {
                    return item.cap;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  onChange={(e, newValue) => changeHandler("MittCAP", newValue)}
                  onInputChange={(e, newValue) => {
                    changeHandler("MittCAP", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cap"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Persona"
              autoFocus
              autoComplete="off"
              value={mittenteForm.MittPersona || ""}
              helperText={touched.MittPersona && errors.MittPersona}
              error={Boolean(touched.MittPersona && errors.MittPersona)}
              onChange={(e) => changeHandler("MittPersona", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Telefono"
              autoComplete="off"
              value={mittenteForm.MittTelefono || ""}
              helperText={touched.MittTelefono && errors.MittTelefono}
              error={Boolean(touched.MittTelefono && errors.MittTelefono)}
              onChange={(e) => changeHandler("MittTelefono", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="E-mail"
              autoComplete="off"
              value={mittenteForm.MittEmail || ""}
              helperText={touched.MittEmail && errors.MittEmail}
              error={Boolean(touched.MittEmail && errors.MittEmail)}
              onChange={(e) => changeHandler("MittEmail", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Orari"
              autoComplete="off"
              value={mittenteForm.Orari || ""}
              helperText={touched.Orari && errors.Orari}
              error={Boolean(touched.Orari && errors.Orari)}
              onChange={(e) => changeHandler("Orari", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          {riferimentoVisible ? (
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                disabled={readOnly}
                margin="normal"
                fullWidth
                size="small"
                label="Riferimento"
                autoComplete="off"
                value={mittenteForm.MittRiferimento || ""}
                helperText={touched.MittRiferimento && errors.MittRiferimento}
                error={Boolean(
                  touched.MittRiferimento && errors.MittRiferimento
                )}
                onChange={(e) =>
                  changeHandler("MittRiferimento", e.target.value)
                }
                //disabled={checked}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Nota ritiro"
              autoComplete="off"
              multiline
              value={mittenteForm.NotaRitiro || ""}
              helperText={touched.NotaRitiro && errors.NotaRitiro}
              error={Boolean(touched.NotaRitiro && errors.NotaRitiro)}
              onChange={(e) => changeHandler("NotaRitiro", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          {indicatoriConfig.visible ? (
            <Grid item xs={12} md={12} lg={12}>
              <Card
                elevation={0}
                sx={{
                  background: (theme) => theme.palette.warning.main + "20",
                }}
              >
                <CardHeader
                  subheader={"Accessori mittente"}
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                  }}
                  avatar={<DragIndicatorTwoTone color={"primary"} />}
                />
              </Card>
            </Grid>
          ) : (
            <></>
          )}
          {indicatoriConfig?.visible ? (
            indicatoriMitt?.map((data, index) => {
              return (
                <Grid item xs={12} md={2} lg={2} key={index}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={checkedIndicatori?.includes(data.codice)}
                          onChange={(e) =>
                            changeIndicatoriChecked(
                              e.target.checked,
                              data.codice
                            )
                          }
                        />
                      }
                      label={data.descrizione}
                    />
                  </FormGroup>
                </Grid>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Mittente;

import * as React from "react";
import {
  Avatar,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Badge,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NationsContext, TrackingContext } from "../context";
import { useEffect } from "react";
import PublishIcon from '@mui/icons-material/Publish';
import {
  AddBoxTwoTone,
  LocalShipping,
  PrintTwoTone,
} from "@mui/icons-material";
import { DialogAlertDelete, FilterBar, CreazioneDistintaDialog, UploadDialog } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import AlertDialogSlide from "../components/AlertDialogSlide";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato =[
  { value:"INS", text:"Inserito"},
  { value:"INSDIS", text:"Distinta creata"},
  { value:"PRC", text:"Preso in carico"},
]

const Spedizioni = () => {
  const { t } = useTranslation("common", { keyPrefix: "spedizioni" });
  const [pageResetVar, setPageResetVar] = useState(false);
  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false); 
  const [NPage, setNPage] = useState(0)
  const [ pageOffset, setPageOffset ] = useState(0);


  const {
    state: {
      spedizioniList,
      columnsCustomSped,
      recordCount,
      filter,
      configPrint,
      resettaFiltri,
      isReminder,
      azioni,
      countNoDistinta,
      dataNoDistinta,
      spedizioniNoDistintaList,
      testDoc,
      filtriStatoSpeidizioni,
    },
    aggiornaFiltri,
    resetFiltri,
    getConfigModeSpedizioni,
    getSpedizioniList,
    getPrintConfig,
    postCreaDistinta,
    delSped,
    spedizioniEmail,
    getCountSenzaDistinta,
    getSpedizioniSenzaDistinta,
    resetNoDistintaData,
    postCreaRitiroDaSpedizioni,
    postUploadFileSpedizioni} = useContext(TrackingContext);

  const {
    getNationsListsData,
  } = useContext(NationsContext);
  
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [openDistintaDialog, setOpenDistintaDialog] = useState(false);
  const [creaRitiroChecked, setCreaRitiroChecked] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDistintaDialogAnnulla = () => {
    setOpenDistintaDialog(false);
  };

  const handleDistintaDialogConferma = () => {
    postCreaDistinta(spedizioniNoDistintaList, navigate, setCheckBoxSelectionPrint, setReload);
    if (creaRitiroChecked){
      //postCreaRitiroDaSpedizioni(spedizioniNoDistintaList,navigate);
    };
    resetNoDistintaData();
    setOpenDistintaDialog(false);
  };

  const handleListItemClick = (codicedoc, checkBoxSelectionPrint) => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");
    let lista = [];
    checkBoxSelectionPrint.forEach((element) => {
      lista = [...lista, element.PrgInterno];
    });
    window.open(
      `${splitPrm[0]}${"/spedizioni"}/print/${lista.toString()}/${codicedoc}`,
      "_blank",
      "noopener,noreferrer"
    );
    setOpen(false);
  };


  const handleCreaDistintaClick = () => {
    if(checkBoxSelectionPrint.length > 0 ){
      postCreaDistinta(
        checkBoxSelectionPrint,
        null,
        setCheckBoxSelectionPrint,
        setReload
      );
      // la seguente dovrà essere gestita lato IRS come opzione indicata nel file INI
      //postCreaRitiroDaSpedizioni(checkBoxSelectionPrint,navigate);

    }

    else{
      getSpedizioniSenzaDistinta(
        "",
        limit,
        offset,
        navigate,
        [],
        recordCount,
        false,
        false,
        dataNoDistinta.StartDate,
        dataNoDistinta.EndDate
      );
      setOpenDistintaDialog(true)
    }
  }

  useEffect(() => {
    titlePage("", t("titlePage"));
    if(!isReminder){
      getSpedizioniList(
        "",
        limit,
        offset,
        navigate,
        [],
        recordCount,
        false,
        filter,
        false,
      );
    }
    getCountSenzaDistinta();
    getConfigModeSpedizioni();
    getPrintConfig();
    setReload(false);
    getNationsListsData()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const deleteAction=(e) =>{
    setOpenAlertDelete(true)
    setIdDelete(e)
  }

  const emailActionSpedizioni=(e) =>{
    spedizioniEmail(e)
  }

  const handleUploadOpen = (e) =>{
    //spedizioniEmail(e)
    setIsUploadDialogOpen(true)
  }


  return (
    <div>
        <Grid container spacing={2}>
        <Grid container item mr={2} spacing={2} direction={"row"}>
          <AlertDialogSlide isRemider={isReminder} />
          <Grid item xs={12} md={12} lg={12}>
            <FilterBar
              /*   setOpenTabella={setOpenTabella} */
              type={true}
              recordCount={recordCount}
              filter={filter}
              aggiornaFiltri={aggiornaFiltri}
              getMethod={getSpedizioniList}
              resetFiltri={resetFiltri}
              setViewStatoWeb={true}
              setViewAdvFilter={true}
              setPageResetVar={setPageResetVar}
              pageResetVar={pageResetVar}
              listStatoWeb={listaStato}
              setCounterApi={setPageOffset}
              setPage={setNPage}
            ></FilterBar>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ButtonGroup size={"large"} orientation={"horizontal"}>

            <Button onClick={()=> navigate("/spedizioni/new")} startIcon={<AddBoxTwoTone />} variant="contained">{t("creaSpedizione")}</Button>

            <Button onClick={handleUploadOpen} startIcon={<PublishIcon></PublishIcon>} variant="contained">
              Importa dati
            </Button>

            <Button
              variant="text"
              startIcon={<PrintTwoTone />}
              disabled={checkBoxSelectionPrint.length < 1}
              onClick={() => setOpen(!open)}
            >
              {t("Stampa")}{" "}
              {checkBoxSelectionPrint.length < 1
                ? ""
                : "(" + checkBoxSelectionPrint.length + ")"}{" "}
            </Button>

            <Dialog onClose={handleClose} open={open}>
              <DialogTitle>{t("SelezionaStampa")}</DialogTitle>
              <List sx={{ pt: 0 }}>
                {configPrint.map((print) => (
                  <ListItemButton
                    onClick={() =>
                      handleListItemClick(print.codicedoc, checkBoxSelectionPrint)
                    }
                    key={print.codicedoc}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: red[400] }} size="small">
                        <PrintTwoTone color="inherit" size="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={print.descrizione} />
                  </ListItemButton>
                ))}
              </List>
            </Dialog>
            {checkBoxSelectionPrint.length < 1 ? (
            <Badge badgeContent={countNoDistinta} color="error" >
              <Button
                variant="text"
                startIcon={<LocalShipping />}
                disabled={countNoDistinta < 1}
                onClick={() =>
                  handleCreaDistintaClick()
                }
              >              
                {t("CreaDistinta")}
              </Button>
            </Badge>
            ):(
              <Badge badgeContent={checkBoxSelectionPrint.length} color="error" >
                <Button
                  variant="text"
                  startIcon={<LocalShipping />}
                  disabled={checkBoxSelectionPrint < 1}
                  onClick={() =>
                    handleCreaDistintaClick()
                  }
                >              
                  {t("CreaDistinta")}
                </Button>
            </Badge>
            )
          }  

          </ButtonGroup>


          {columnsCustomSped.length > 0 ? (
            <CustomPaginationActionsTable
              setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
              rows={spedizioniList}
              columns={columnsCustomSped}
              limit={100}
              navigate={navigate}
              getMethodForList={getSpedizioniList}
              recordCount={recordCount}
              routeDetail={"/spedizioni"}
              idElement={"PrgInterno"}
              actionShow={true}
              viewAction={{
                mail: azioni?.email?.visible,
                view: true,
                location: false,
                print: false,
                edit:azioni?.modifica?.visible,
                delete:azioni?.annulla?.visible,
              }}
              pageSearchReset={false}
              pageReset={pageResetVar}
              setPageReset={setPageResetVar}
              filterList={filter}
              resettaFiltri={resettaFiltri}
              configPrint={configPrint}
              reload={reload}
              emailAction={(e)=>emailActionSpedizioni(e)}
              deleteAction={(e)=>deleteAction(e)}
              counterApi={pageOffset}
              setCounterApi = {setPageOffset}
              page={NPage}
              setPage = {setNPage}
            />
          ) : (
            <Stack justifyContent={"center"} alignContent="center">
              <Typography>Caricamento...</Typography>
              <LinearProgress color="success" />
            </Stack>
          )}
        </Grid>

        <DialogAlertDelete 
          open={openAlertDelete} 
          setOpen={setOpenAlertDelete} 
          idDelete={idDelete} 
          delFunc={delSped} 
          setIdDelete={setIdDelete} 
          title={"spedizione"}
        />
        
        {/* dialog conferma creazione distinte multiple mancanti */}
        <CreazioneDistintaDialog 
          isOpen={openDistintaDialog}
          setIsOpen={setOpenDistintaDialog}
          handleAnnulla={handleDistintaDialogAnnulla}
          handleConferma={handleDistintaDialogConferma}
          creaRitiroChecked={creaRitiroChecked}
          setCreaRitiroChecked={setCreaRitiroChecked}
        />    
      
      </Grid>
      <UploadDialog 
        open={isUploadDialogOpen}
        setOpen={setIsUploadDialogOpen}
        testDoc={testDoc}
        postFile={postUploadFileSpedizioni}
        dialogTitle={"Importa spedizioni da file"}
      />      
    </div>

  );
};

export default Spedizioni;

import React, { useContext, useState, useEffect } from 'react'
import { AuthContext, PreventiviNewContext } from "../../context";
import { AssignmentTwoTone } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Grow,
  Switch,
  Stack,
  Alert,
  Button,
  CardActions,
  AlertTitle,
  Container,
  Typography
} from "@mui/material";
import { orange } from '@mui/material/colors';
import { CurrencySimbol } from "../../utilities";
import { v4 as uuidv4 } from "uuid";




const RiepilogoPreventivo = () => {

  const [riepilogoChecked, setRiepilogoChecked] = useState(false/*nuovoPreventivoForm.sceltaContrassegno*/);

  const {
    state: {
      form,
      result,
      indicatoriMitt,
      indicatoriDest
    },
    popolaNuovoPreventivo
  } = useContext(PreventiviNewContext);


  useEffect(() => {
    popolaNuovoPreventivo(form);
  }, [])

  function accessoriMittente() {
    let ret = ""
    for (let i = 0; i < form.IndicatoriMittente.length; i++) {
      indicatoriMitt.map((element) => {
        if(element.codice === form.IndicatoriMittente[i]){
          if(i === 0){
            ret = ret + `${element.descrizione} `;
          }
          else{
            ret = ret + `<br>${element.descrizione} `;
          }
        }
      })
    }
    return (ret);
  }

  function accessoriDestinatario() {
    let ret = ""
    for (let i = 0; i < form.indicatoriDestinatario.length; i++) {
      indicatoriDest.map((element) => {
        if(element.codice === form.indicatoriDestinatario[i]){
          if(i === 0){
            ret = ret + `${element.descrizione} `;
          }
          else{
            ret = ret + `<br>${element.descrizione} `;
          }
        }
      })
    }
    return (ret);
  }

  
  function dettaglioCOD() {
    let riga = `${form.ImportoCOD} ${CurrencySimbol(form.ValutaCOD)}    ${form.IncassoDescrizione}`;
    return(
      <Container>
        <strong>
          Contrassegno
        </strong>
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {riga}
        </Typography>
      </Container>
    )
  };

  function assicurazioneData() {
    return (
      <CardActions sx={{ justifyContent: "left", direction: "row" }}>
        <Stack direction={"row"} spacing={2}>
          <Button variant="text"><strong>Assicurazione:</strong></Button>
          <Button variant="text">Valuta: {form.StimaValMerceValuta}</Button>
          <Button variant="text">Importo: {form.StimaValMerce} </Button>
        </Stack>
      </CardActions>
    )
  }


  function generaRigaMerce(row){
    let riga = ``;
    if (row.Tipo === `C`){
      if(row.Quantita === "1"){
        riga = riga + `${row.Quantita} collo    `
      }
      else {
        riga = riga + `${row.Quantita} colli    `     
      }
    }
    else if (row.Tipo === "P"){
      if(row.Quantita === "1"){
        riga = riga + `${row.Quantita} bancale  `
      }
      else {
        riga = riga + `${row.Quantita} bancali  `    
      }
    }
    riga = riga + `${row.Altezza} x ${row.Lunghezza} x ${row.Larghezza} m     `;
    riga = riga + row.Peso + " kg\n";
    return(riga);
  }


  function dettaglioMerce() {
    return(
      <Container>
        <strong>
          Dettaglio merce:
        </strong>
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {form.ordmerce.map((row) => (
  
                generaRigaMerce(row) 
          ))}
        </Typography>
      </Container>
    )
  };
 


  function generaRigaMerceADR(row){
    let riga = ``;



    if(row.Colli === "1"){
        riga = riga + `${row.Colli} collo  `;
    }
    else {
      riga = riga + `${row.Colli} colli  `   ;  
    }

    riga = riga + row.Peso + " kg ";

    riga = riga + `Classe: ${row.Classe}  ONU: ${row.NumONU} Imballaggio: ${row.Imballaggio}\n`;

    return(riga);
  }

  function dettaglioMerceADR() {
    return(
      <Container>
        <strong>
          Dettaglio merce ADR:
        </strong>
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {form.ordadr.map((row) => (  
                generaRigaMerceADR(row) 
          ))}
        </Typography>

      </Container>
    )
  };

  

  return (
    <   Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.info.main + "20" }}
    >
      <CardHeader
        title={"Riepilogo"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<AssignmentTwoTone />}
        action={
          <FormControlLabel
            control={
              <Switch checked={riepilogoChecked} onClick={() => setRiepilogoChecked(!riepilogoChecked)} />
            }
            label=""
          />
        }
      />

      {riepilogoChecked ? (
        <Grow in={riepilogoChecked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <CardContent>
            <Grid item container spacing={1} key={uuidv4()}>
              {form?.ordmerce !== "" ? (
                <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>

                        {form.ordmerce ? dettaglioMerce() : ""}


                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                <></>
              )}

              {form?.ordadr?.length > 0 ? (
                <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                      {form.ordadr.length > 0 ? dettaglioMerceADR() : ""}
                    </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                <></>
              )}

              {(form?.ImportoCOD !== "" && form?.ImportoCOD !== undefined || form.StimaValMerce !== "" && form?.StimaValMerce !== undefined) ? (
                <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>
                      <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                        {form.ImportoCOD !== "" && dettaglioCOD()}
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                        {form.StimaValMerce !== "" ? assicurazioneData() : ""}
                      </Stack>
                    </CardContent>
                  </Card>      
                </Grid>
              ) : (
                <></>
              )}

              {form?.IndicatoriMittente != undefined && form?.IndicatoriMittente?.length > 0 ? (
                <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
                  <Alert severity="info" icon={<></>} >
                    <AlertTitle><strong>Accessori Mittente</strong></AlertTitle>
                    {
                      <ReactMarkdown
                        children={accessoriMittente()}
                        rehypePlugins={[rehypeRaw]}
                      >{accessoriMittente()}</ReactMarkdown>
                    }{" "}
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}

              {form?.indicatoriDestinatario != undefined && form?.indicatoriDestinatario.length > 0 ? (
                <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
                  <Alert severity="info" icon={<></>} >
                    <AlertTitle><strong>Accessori Destinatario</strong></AlertTitle>
                    {
                      <ReactMarkdown
                        children={accessoriDestinatario()}
                        rehypePlugins={[rehypeRaw]}
                      >{accessoriDestinatario()}</ReactMarkdown>

                    }{" "}
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}

            </Grid>
          </CardContent>
        </Grow>

      ) : (
        <></>
      )}


    </Card>
  )
}

export default RiepilogoPreventivo
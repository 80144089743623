import React from "react";
import {
  AddTaskTwoTone,
  AdminPanelSettingsTwoTone,
  AirlineStopsTwoTone,
  CheckCircleOutlineTwoTone,
  ConnectWithoutContactTwoTone,
  DashboardTwoTone,
  DeviceHubTwoTone,
  DirectionsTwoTone,
  DocumentScannerTwoTone,
  DoubleArrowTwoTone,
  EditRoadTwoTone,
  EventRepeat,
  HowToRegTwoTone,
  HubTwoTone,
  LocalShippingTwoTone,
  LocationOnTwoTone,
  Logout,
  NetworkPingTwoTone,
  PanToolTwoTone,
  PeopleOutlineTwoTone,
  PreviewTwoTone,
  SwipeDownTwoTone,
  TransferWithinAStationTwoTone,
  WarehouseTwoTone,
  
} from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";
import {
  amber,
  blue,
  cyan,
  deepPurple,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import EventNoteIcon from '@mui/icons-material/EventNote';



const vociMenu = [
  {
    menuName: "Cruscotto",
    icon: <DashboardTwoTone fontSize={"small"} color="primary" />,
    route: "/dashboard",
    title: "Dashboard",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "dashboard",
  },
  {
    menuName: "Tracking",
    icon: <LocationOnTwoTone fontSize={"small"} color="primary" />,
    route: "/tracking",
    title: "Tracking",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "tracking",
  },
  {
    menuName: "Ritiri",
    icon: <LocalShippingTwoTone fontSize={"small"} color="primary" />,
    route: "/ritiri",
    title: "Ritiri",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "ritiri",
  },
  {
    menuName: "Spedizioni",
    icon: <AirlineStopsTwoTone fontSize={"small"} color="primary" />,
    route: "/spedizioni",
    title: "Spedizioni",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "spedizioni",
  },
  {
    menuName: "Anagrafiche",
    icon: <PeopleOutlineTwoTone fontSize={"small"} color="primary" />,
    route: "/indirizzi-codificati",
    title: "Anagrafiche",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "anagrafiche",
  },
  {
    menuName: "Documenti",
    icon: <DocumentScannerTwoTone fontSize={"small"} color="primary" />,
    route: "/documenti",
    title: "Documenti",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "documenti",
  },

  {
    menuName: "Preventivi",
    icon: <PreviewTwoTone fontSize={"small"} color="primary" />,

   
    route: "/preventivi",
    title: "Preventivo",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "preventivi",
  },

  {
    menuName: "Nuovo Preventivo",
    icon: <PreviewTwoTone fontSize={"small"} color="primary" />,
    route: "/preventivo",
    title: "Preventivo",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "preventivo",
  },



  {
    menuName: "",
    icon: <NetworkPingTwoTone fontSize={"small"} color="primary" />,
    route: "/link-terzi",
    title: "",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "sitoesterno1",
  },



  /*{
    menuName:"Corrispondente",
    icon: <PreviewTwoTone fontSize={"small"} color="primary" />,
    route: "/corrispondente",
    title: "Corrispondente",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    idMenu: "corrispondente",
  }*/
];

const currencyData = [
  {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars",
  },
  {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    code: "CHF",
    name_plural: "Swiss francs",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GBP",
    name_plural: "British pounds sterling",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    code: "FCH",
    name_plural: "Swiss francs",
  },
];
export function ConfigClient() {
  let parseConfig = JSON.parse(localStorage.getItem("configurazione"));
  let content = [];
  parseConfig?.forEach((element) => {
    if (element.visible === true) {
      let filterPage = vociMenu.find((res) => res.idMenu === element.name);
      if (filterPage !== undefined) {
        if(element.title !== undefined){
          filterPage.route = element.url
          filterPage.title = element.title
        }
        content = [...content, filterPage];
      }
    }
  });
  return content;
}

export const settingsMenu = [
  {
    menuName: "Disconnetti",
    icon: <Logout color="inherit" />,
    route: "/logout",
    title: "Disconnetti",
    width: "40%",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    visible: true,
    idMenu: 2,
  },
];

//THEMES
export const themeChoseLight = [
  //009139
  {
    palette: {
      type: "light",
      primary: {
        main: "#0857f7",
      },
      secondary: {
        main: "#ede7f6",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
      },
      warning: {
        main: "#ffaa00",
      },
    },
  },
  {
    palette: {
      type: "light",
      primary: {
        main: "#d11002",
      },
      secondary: {
        main: "#ffd9d6",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
      },
      warning: {
        main: "#ffaa00",
      },
    },
  },

  {
    palette: {
      type: "light",
      primary: {
        main: "rgba(2,33,123,0.63)",
      },
      secondary: {
        main: "#ffffff",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
      },
    },
  },
  {
    palette: {
      type: "light",
      primary: {
        main: "rgba(0,122,206,0.99)",
      },
      secondary: {
        main: "#a9eef7",
        contrastText: "#ffffff",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
      },
    },
  },

  {
    
    palette: {
      type: 'light',
      primary: {
        main: '#ff6600',
      },
      secondary: {
        main: '#ede7f6',
      },
      text: {
        primary: 'rgba(0,0,0,0.87)',
      },
      warning: {
        main: '#ffaa00',
      },
    },
  },
  {
    palette: {
      type: "light",
      primary: {
        main: "#009139",
      },
      secondary: {
        main: "#ede7f6",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
      },
      warning: {
        main: "#ffaa00",
      },
    },
  },
];
export function iconStateTracking(StateTracking, desc) {
  let icon = <></>;
  switch (StateTracking) {
    case "INSDIS":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
           <IconButton color="inherit" size="small">
            <AddTaskTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "INS":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <AddTaskTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "PRC":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <SwipeDownTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "PREN":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <EventNoteIcon/>
          </IconButton>
        </Tooltip>
      );
      break;
    case "ASS1":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <EditRoadTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "RIT":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <TransferWithinAStationTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "MAG1":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <WarehouseTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "HUB1":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <DeviceHubTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "TRAZ":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <DoubleArrowTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "TRAN":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <DirectionsTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "HUB2":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <HubTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "MAG2":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <AdminPanelSettingsTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "GIA":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <PanToolTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "AFF":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <AirlineStopsTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "ASS2":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <HowToRegTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "INC":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <LocalShippingTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "CON":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <CheckCircleOutlineTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "RDE":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <ConnectWithoutContactTwoTone />
          </IconButton>
        </Tooltip>
      );
      break;
    case "RTV":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          RTV
          <IconButton color="inherit" size="small"></IconButton>
        </Tooltip>
      );
      break;
    case "NRIT":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          NRIT
          <IconButton color="inherit" size="small"></IconButton>
        </Tooltip>
      );
      break;
    case "NCON":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          NCON
          <IconButton color="inherit" size="small"></IconButton>
        </Tooltip>
      );
      break;
    case "INR":
      icon = (
        <Tooltip title={desc ? desc : StateTracking}>
          <IconButton color="inherit" size="small">
            <EventRepeat />
          </IconButton>
        </Tooltip>
      );
      break;
    default:
      return icon;
  }
  return icon;
}
export function chipIconState(StateTracking, desc) {
  let icon = <></>;
  switch (StateTracking) {
    case "INS":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AddTaskTwoTone />}
          sx={{ background: cyan[100], color:"black"  }}
          size="small"
        />
      );
      break;
      case "INSDIS":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AddTaskTwoTone />}
          sx={{ background: yellow[50], color:"black"  }}
          size="small"
        />
      );
      break;
    case "PRC":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<SwipeDownTwoTone />}
          sx={{ background: green[100], color:"black" }}
          size="small"
        />
      );
      break;
    case "PREN":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<SwipeDownTwoTone />}
          sx={{ background: red[50], color:"black" }}
          size="small"
        />
      );
    break;
    case "ASS1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<EditRoadTwoTone />}
          sx={{ background: yellow[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "RIT":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<TransferWithinAStationTwoTone />}
          sx={{ background: pink[100], color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "MAG1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<WarehouseTwoTone />}
          sx={{ background: red[100], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "HUB1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DeviceHubTwoTone />}
          sx={{ background: indigo[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "TRAZ":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DoubleArrowTwoTone />}
          sx={{ background: blue[100], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "TRAN":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DirectionsTwoTone />}
          sx={{ background: teal[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "HUB2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<HubTwoTone />}
          sx={{ background: lime[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "MAG2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AdminPanelSettingsTwoTone />}
          sx={{ background: lime.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "GIA":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<PanToolTwoTone />}
          sx={{ background: red.A100, color:"black"  }}
          size="small"
        />
      );
      break;
    case "AFF":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AirlineStopsTwoTone />}
          sx={{ background: yellow.A100 , color:"black"  }}
          size="small"
        />
      );
      break;
    case "ASS2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<HowToRegTwoTone />}
          sx={{ background: deepPurple[100] , color:"black"  }}
          size="small"
        />
      );
      break;
    case "INC":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<LocalShippingTwoTone />}
          sx={{ background: amber[200] , color:"black"  }}
          size="small"
        />
      );
      break;
    case "CON":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<CheckCircleOutlineTwoTone />}
          sx={{ background: green.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "RDE":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<ConnectWithoutContactTwoTone />}
          sx={{ background: orange[50], color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "RTV":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: orange[50], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "NRIT":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: red.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "NCON":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: purple.A200, color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "INR":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          icon={<EventRepeat />}
          sx={{ background: red.A400, color:"black" }}
          variant="outlined"
          size="small"

        />
      );
      break;
    default:
      return icon;
  }
  return icon;
}
export function titleAppBar(params, titoloPassato, t) {
  
  if (params !== "/dashboard") {
    let find = vociMenu.find((res) => res.route === params);
    if (find) {
      if (params === "/preventivi") {
        return ("Nuovo Preventivo")
      }
      else {
        return t(find.menuName);
      }
    }
    else {
      return t(titoloPassato);
    }
    } else {
      return t("Cruscotto");
    }
}
export function titlePage(nomeAzienda, pagina) {
  document.title =
    localStorage.getItem("titleshort") === null
      ? nomeAzienda + " - " + localStorage.getItem("titleshort")
      : localStorage.getItem("titleshort") + " - " + pagina;
}

export function getFlagPath(basePath, nazioneiso) {
  let url = "";

  if(nazioneiso !== ""){
    let splitPrm = basePath.split("/");
    url = `${splitPrm[0]}//${splitPrm[2]}/flags/${nazioneiso}.png`;

  }

  return(url)
}

  //06/06/2024 Greg: Per diminuire carico su IRS effetto solo un'unica ricerca anzichè
  //                 effetturare ricerca a ogni nuovo carattere digitato.
  export function setTimerSearchAnagrafica(searchTimer,setSearchTimer, getSearchAnagrafiche,newValue){    
    //se già impostato un timer lo butto via
    if (searchTimer !== null){
      clearTimeout(searchTimer);
    }

    //metto la funzione temporizzata in anagraficheSearchTimeOut
    setSearchTimer(
      setTimeout(
        () => {getSearchAnagrafiche(newValue)},
        500  
      )
    )
  }


export const getConfig = async (params) => {
  let config = {};
  let splitPrm = params.split("/");
  let parametro = splitPrm[2];
  let response = {}
  if (parametro === "localhost:3000"){
    response = await fetch(
      `https://configuration-irs.incontraweb.it/?domain=${parametro}`
    )
  }
  else{
    response = await fetch(
      `${splitPrm[0]}//${splitPrm[2]}/config`
      );
  }

  const data = await response.json();
  localStorage.setItem("titlelong", data.titlelong);
  localStorage.setItem("titleshort", data.titleshort);
  config = data;
  return config;
};
export const CurrencySimbol = (code) => {
  let symbol = "";
  currencyData.forEach((element) => {
    if (code === element.code) {
      symbol = element.symbol;
    }
  });
  return symbol;
};
